import React from "react";
import Accordion from "../../common/accordion";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";
import { Link } from "react-router-dom";
import "./index.scss";
function Careers() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [careers, setCareers] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}career/all`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setCareers(response.data.items.en);
        console.log(response.data.items.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "Error";
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="career-container">
              {careers.length === 0
                ? "No Any vacancy"
                : careers.map((career, index) => {
                    return (
                      <>
                        <div className="CarrersBox" key={index}>
                          <div className="CareerTitle">
                            <h2>{career.title}</h2>
                            <Link to={career.slug}>
                              <button>Apply Now</button>
                            </Link>
                          </div>
                          <div className="branch">
                            <h3>Branches : </h3>
                            {career.branches?.map((branch, index) => {
                              return (
                                <>
                                  <span key={index}>
                                    {branch.PageTitle} {`,`}
                                  </span>
                                </>
                              );
                            })}
                          </div>
                          <div className="numbers">
                            <h3>
                              Number of Opening :<span>{career.jobNumber}</span>
                            </h3>
                            <h3>
                              Application Deadline :
                              <span>{career.EndDate}</span>
                            </h3>
                          </div>
                          <div className="description">
                            <div
                              className="CKContentBox"
                              dangerouslySetInnerHTML={{
                                __html: career?.description
                                  ? career?.description
                                  : "",
                              }}
                            ></div>
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;

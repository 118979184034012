import React, { useEffect, useState } from "react";
// const [validationError, setValidationError] = useState(null);
import { TabTitle } from "./dynamicTitle";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./careerForm.scss";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import file from "../assets/img/file.png";
import SelectBranch from "./SelectBranch";
import ProvienceSelect from "./ProvienceSelect";

import SelectDistrict from "./SelectDistrict";

function CareerForm() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [Data, setData] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}Career/detail/${slug}`;
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setData(response.data.detail.en);
        //console.log("C_________DATA", response.data.detail.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    formState,
  } = useForm();
  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [convert, setConvert] = React.useState();
  const [convertCert, setcertConvert] = React.useState();
  const APIURLP = `${process.env.REACT_APP_URL}province`;
  const APIURLD = `${process.env.REACT_APP_URL}district/all`;
  const [Provience, setProvience] = React.useState();
  const [SProvience, setSProvience] = React.useState();
  const [TProvience, setTProvience] = React.useState();
  const [SDistict, setSDistict] = React.useState();
  const [STDistict, setSTDistict] = React.useState();
  const [fileName, setFileName] = useState("");
  const [fileNameCV, setFileNameCV] = useState("");
  const [fileNamepic, setFileNamepic] = useState("");
  const [fileNamecitizen, setfileNamecitizen] = useState("");
  const [CVsss, setCVsss] = React.useState();
  const [image, setimage] = React.useState();
  const [sertificate, setCertificate] = React.useState("");
  const [citizenship, setCitizenship] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURLP);
        setProvience(response.data.provience.en);
        // console.log("API", response.data.provience.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  const handleDobChange = (event) => {
    const dob = new Date(event.target.value);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    setValue("age", age); // Update the age field
  };
  const calculateTotalMonths = (dateFrom, dateTo) => {
    if (!dateFrom || !dateTo) return;

    const start = new Date(dateFrom);
    const end = new Date(dateTo);

    let totalMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    // Adjust if the end date is before the start date
    if (end.getDate() < start.getDate()) {
      totalMonths--;
    }

    setValue("total_month", totalMonths); // Update total_month field
  };

  const [educationaddmore, setEducationaddmore] = useState([
    {
      organization: "",
      board: "",
      degree: "",
      faculty: "",
      passed_year: "",
    },
    {
      organization: "",
      board: "",
      degree: "",
      faculty: "",
      passed_year: "",
    },
    {
      organization: "",
      board: "",
      degree: "",
      faculty: "",
      passed_year: "",
    },
    {
      organization: "",
      board: "",
      degree: "",
      faculty: "",
      passed_year: "",
    },
  ]);
  // Education Start
  const educationAdd = () => {
    setEducationaddmore([
      ...educationaddmore,
      {
        organization: "",
        board: "",
        degree: "",
        faculty: "",
        passed_year: "",
      },
    ]);
  };
  const educationRemove = (index) => {
    const list = [...educationaddmore];
    list.splice(index, 1);
    setEducationaddmore(list);
  };
  const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...educationaddmore];
    list[index][name] = value;
    setEducationaddmore(list);
  };
  // Education Ends

  // Job Experience start
  const [experienceaddmore, setExperienceaddmore] = useState([
    {
      organization: "",
      position: "",
      date_from: "",
      date_to: "",
      department: "",
      total_month: "",
    },
  ]);

  // Experience Start
  const experienceAdd = () => {
    setExperienceaddmore([
      ...experienceaddmore,
      {
        organization: "",
        position: "",
        date_from: "",
        date_to: "",
        department: "",
        total_month: "",
      },
    ]);
  };
  const experienceRemove = (index) => {
    const list = [...experienceaddmore];
    list.splice(index, 1);
    setExperienceaddmore(list);
  };
  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...experienceaddmore];
    list[index][name] = value;

    // Calculate total months if both dates are present
    if (list[index].date_from && list[index].date_to) {
      const dateFrom = new Date(list[index].date_from);
      const dateTo = new Date(list[index].date_to);

      // Calculate the difference in months
      const totalMonths =
        (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
        (dateTo.getMonth() - dateFrom.getMonth());

      list[index].total_month = totalMonths >= 0 ? totalMonths : 0; // Ensure non-negative
    } else {
      list[index].total_month = ""; // Clear total months if dates are not both present
    }

    setExperienceaddmore(list);
  };

  // Job Experience Ends

  // Tarining Starts

  const [trainingaddmore, setTrainingaddmore] = useState([
    {
      organization: "",
      training_date: "",
      training_date_to: "",
      title: "",
    },
  ]);

  // Trainingaddmore Start
  const trainingAdd = () => {
    setTrainingaddmore([
      ...trainingaddmore,
      {
        organization: "",
        training_date: "",
        training_date_to: "",
        title: "",
      },
    ]);
  };
  const trainingRemove = (index) => {
    const list = [...trainingaddmore];
    list.splice(index, 1);
    setTrainingaddmore(list);
  };
  const handleTrainingChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...trainingaddmore];
    list[index][name] = value;
    setTrainingaddmore(list);
  };
  // Trainingaddmore Ends

  const [referencesaddmore, setReferencesaddmore] = useState([
    {
      organization: "",
      name: "",
      position: "",
      address: "",
      contact: "",
    },
  ]);
  // referencesaddmore Start
  const referencesAdd = () => {
    setReferencesaddmore([
      ...referencesaddmore,
      {
        organization: "",
        name: "",
        position: "",
        address: "",
        contact: "",
      },
    ]);
  };
  const referencesRemove = (index) => {
    const list = [...referencesaddmore];
    list.splice(index, 1);
    setReferencesaddmore(list);
  };
  const handleReferencesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...referencesaddmore];
    list[index][name] = value;
    setReferencesaddmore(list);
  };

  const ChangeProvience = (e) => {
    setSProvience(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/provience_wise_district/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="">Select District</option>`;
          response?.data?.district?.map((district, index) => {
            $html += `<option key=${index + 1} value=${district.id}>
                ${district.title}
              </option>`;
          });
          document.getElementById("permanent_district").innerHTML = $html;
        }
      });
  };

  const ChangeDistict = (e) => {
    setSDistict(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/district_wise_municipality/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="0">Select municipality</option>`;
          response?.data?.municipality?.map((municipality, index) => {
            $html += `<option key=${index + 1} value=${municipality.id}>
                ${municipality.title}
              </option>`;
          });
          document.getElementById("permanent_municipality").innerHTML = $html;
        }
      });
  };

  // temporary

  const ChangeProvienceT = (e) => {
    setTProvience(e.target.value);
    // console.log("Temp", e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/provience_wise_district/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="">Select District</option>`;
          response?.data?.district?.map((district, index) => {
            $html += `<option key=${index + 1} value=${district.id}>
                ${district.title}
              </option>`;
          });
          document.getElementById("temporary_district").innerHTML = $html;
        }
      });
  };

  const ChangeDistictST = (e) => {
    setSTDistict(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/district_wise_municipality/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="0">Select municipality</option>`;
          response?.data?.municipality?.map((municipality, index) => {
            $html += `<option key=${index + 1} value=${municipality.id}>
                ${municipality.title}
              </option>`;
          });
          document.getElementById("temporary_municipality").innerHTML = $html;
        }
      });
  };
  const genders = [
    { value: "", label: "Select Gender" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];
  const maritals = [
    { value: "", label: "Select Marital Status" },
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
    { value: "Unmarried", label: "UnMarried" },
    { value: "Divorced", label: "Divorced" },
  ];
  const appconvert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setCertificate(reader?.result.toString());
    };
    reader?.readAsDataURL(file);
  };
  const handleAppChange = (e) => {
    const file = e?.target?.files[0];
    const files = e?.target?.files;
    appconvert2base64(file);
    if (files.length > 0) {
      setFileName(files[0]?.name);
    } else {
      setFileName("");
    }
  };

  const cvconvert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setCVsss(reader.result.toString());
    };
    reader?.readAsDataURL(file);
  };
  const handleCVChange = (e) => {
    const file = e.target.files[0];
    const files = e.target.files;
    cvconvert2base64(file);
    if (files.length > 0) {
      setFileNameCV(files[0].name); // Set the selected file name
    } else {
      setFileNameCV(""); // Reset if no file is selected
    }
  };

  const imageconvert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setimage(reader.result.toString());
    };
    reader?.readAsDataURL(file);
  };
  const handleDocPathChange = (e) => {
    const file = e.target.files[0];
    const files = e.target.files;
    imageconvert2base64(file);
    if (files.length > 0) {
      setFileNamepic(files[0].name);
    } else {
      setFileNamepic("");
    }
  };
  const citizenconvert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setCitizenship(reader.result.toString());
    };
    reader?.readAsDataURL(file);
  };
  const handlecitizenshipChange = (e) => {
    const file = e.target.files[0];
    const files = e.target.files;
    citizenconvert2base64(file);
    if (files.length > 0) {
      setfileNamecitizen(files[0].name);
    } else {
      setfileNamecitizen("");
    }
  };

  const onSubmit = async (formValues) => {
    // console.log("formValues", trainingaddmore, referencesaddmore);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}career/form`,
        {
          ...formValues,
          educationaddmore,
          experienceaddmore,
          trainingaddmore,
          referencesaddmore,
          DocPath: image,
          certificate: sertificate,
          cv: CVsss,
          citizenship,
        },
        { headers: { "content-type": "application/json" } }
      );
      const data = response?.data;
      if (data === null) {
        toast("Unexpected error: server returned null data.");
        return;
      }

      if (response?.status === 200 && data?.status_code === 200) {
        toast(data?.status_message || "Form submitted successfully");
        setData(data);
        reset();
        setReferencesaddmore([
          {
            organization: "",
            name: "",
            position: "",
            address: "",
            contact: "",
          },
        ]);
        setEducationaddmore([
          {
            organization: "",
            board: "",
            degree: "",
            faculty: "",
            passed_year: "",
          },
        ]);
        setExperienceaddmore([
          {
            organization: "",
            position: "",
            date_from: "",
            date_to: "",
            department: "",
            total_month: "",
          },
        ]);
        setTrainingaddmore([
          {
            organization: "",
            training_date: "",
            training_date_to: "",
            title: "",
          },
        ]);
        setTimeout(() => {
          window.location.href = "/careers";
        }, 3000);
      } else {
        setError(true);
        toast(data?.status_message || "Error submitting form");
      }
    } catch (error) {
      setError(true);

      // Log the full error object for better debugging
      console.error("Caught Error:", error);

      if (error.response) {
        console.error("Response Error Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        console.error("Response Headers:", error.response.headers);

        const { data, status, statusText } = error.response;
        toast(data?.status_message || `Server Error: ${status} ${statusText}`);
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast("No response from server. Please try again later.");
      } else {
        console.error("Error Message:", error.message);
        toast("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (Data?.id) {
      setValue("career_id", Data.id);
    }
  }, [Data, setValue]);
  return (
    <>
      <ToastContainer
        className="toast-position"
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="FormPageContainer">
        <div className="Formheader">
          <h2>Apply</h2>
        </div>
        <div className="formssss">
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="FOrmHeader">
              <div className="row">
                <div className="form-group">
                  <input
                    {...register("career_id")}
                    type="hidden"
                    value={Data?.id}
                  />
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="position">Position Applied For</label>
                    <select
                      className="form-control"
                      id="position"
                      required="required"
                      s
                      {...register("position", {
                        required: "This field is required.",
                      })}
                    >
                      <option value="">Select Position</option>
                      {Data?.positions?.map((position, index) => {
                        return (
                          <option key={index} value={position.id}>
                            {position.designation_name}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {errors.position?.message}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="Applied_branch">Applied Branch</label>
                    <select
                      className="form-control"
                      id="Applied_branch"
                      required="required"
                      {...register("branch_name", {
                        required: "This field is required.",
                      })}
                    >
                      <option value="">Select Branch</option>
                      {Data?.branches?.map((apbrnach, index) => {
                        return (
                          <option key={index} value={apbrnach.id}>
                            {apbrnach.PageTitle}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {errors.branch_id?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h4>Personal Details</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    placeholder="First Name"
                    required="required"
                    {...register("first_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid First name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.first_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="middle_name">Middle Name</label>
                  <input
                    type="text"
                    id="middle_name"
                    className="form-control"
                    placeholder="Middle Name"
                    {...register("middle_name", {
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Middle name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.middle_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    required="required"
                    {...register("last_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Last name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.last_name?.message}
                  </span>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="bod">Date of Birth</label>
                  <input
                    type="date"
                    id="bod"
                    className="form-control"
                    {...register("bod", {
                      required: "This field is required.",
                      onChange: handleDobChange,
                    })}
                  />
                  <span className="text-danger">{errors.bod?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="age">Age</label>
                  <input
                    type="text"
                    name="age"
                    placeholder="Age"
                    className="form-control"
                    readOnly
                    {...register("age", {
                      required: "Age is required",
                    })}
                  />
                  <span className="text-danger">{errors.age?.message}</span>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="bod">Place of Birth</label>
                  <input
                    type="text"
                    id="birth_place"
                    className="form-control"
                    required="required"
                    placeholder="Place Of Birth"
                    {...register("birth_place", {
                      required: "This field is required.",
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.birth_place?.message}
                  </span>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="father_name">Father's Name</label>
                  <input
                    type="text"
                    id="father_name"
                    className="form-control"
                    placeholder="Father's Name"
                    required="required"
                    {...register("father_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid  name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.father_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="mother_name">Mother's Name</label>
                  <input
                    type="text"
                    id="mother_name"
                    className="form-control"
                    placeholder="Mother's Name"
                    required="required"
                    {...register("mother_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Last name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.mother_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="grandfather_name">Grandfather's Name</label>
                  <input
                    type="text"
                    id="grandfather_name"
                    className="form-control"
                    placeholder="Grandfather's Name"
                    required="required"
                    {...register("grandfather_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Last name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.grandfather_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Email Address"
                    {...register("email", {
                      required: "Please enter your email.",
                      pattern: {
                        value:
                          /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                        message: "Enter Valid Email address",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="mobno">Phone Number</label>
                  <input
                    type="text"
                    name="mobno"
                    placeholder="Contact Number"
                    className="form-control"
                    required="required"
                    {...register("mobno", {
                      required: "mobno is Required",
                    })}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={10}
                  />
                  <span className="text-danger">{errors.mobno?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="gender">Gender</label>
                  <select
                    className="form-control"
                    id="gender"
                    {...register("gender", {
                      required: "This field is required.",
                    })}
                  >
                    {genders.map((gender) => {
                      return (
                        <option value={gender.value}>{gender.label}</option>
                      );
                    })}
                  </select>

                  <span className="text-danger">{errors.gender?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="nationality">Nationality</label>
                  <input
                    type="text"
                    id="nationality"
                    className="form-control"
                    placeholder="Nationality"
                    required="required"
                    {...register("nationality", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.nationality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="marital_status">Marital Status</label>
                  <select
                    className="form-control"
                    id="marital_status"
                    {...register("marital_status", {
                      required: "This field is required.",
                    })}
                  >
                    {maritals.map((marital) => {
                      return (
                        <option value={marital.value}>{marital.label}</option>
                      );
                    })}
                  </select>

                  <span className="text-danger">
                    {errors.marital_status?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="citizenship_no"> Citizenship Number</label>
                  <input
                    type="text"
                    id="citizenship_no"
                    className="form-control"
                    placeholder="xxxxxx-xxxxx"
                    {...register("citizenship_no", {
                      required: "This field is required",
                    })}
                  />
                  <span className="text-danger">
                    {errors.citizenship_no?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="citizen_issue_location">
                    Citizenship Issue Location
                  </label>
                  <input
                    type="text"
                    id="citizen_issue_location"
                    className="form-control"
                    required="required"
                    placeholder="Citizenship Issue Location"
                    {...register("citizen_issue_location", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid name",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.citizen_issue_location?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a5">Permanent Address</h4>
            <div className="row">
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="permanent_provience">Province</label>
                  <select
                    className="form-control"
                    {...register("permanent_provience", {
                      required: "This field is required.",
                    })}
                    id="permanent_provience"
                    required="required"
                    value={SProvience}
                    onChange={ChangeProvience}
                  >
                    <option value="">Select Province</option>
                    <ProvienceSelect />
                  </select>
                  <span className="text-danger">
                    {errors.permanent_provience?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="permanent_district">District</label>
                  <select
                    className="form-control"
                    {...register("permanent_district", {
                      required: "This field is required.",
                    })}
                    id="permanent_district"
                    required="required"
                    value={SDistict}
                    onChange={ChangeDistict}
                  >
                    <option value="">Select Distict</option>
                    <SelectDistrict />
                  </select>
                  <span className="text-danger">
                    {errors.permanent_district?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="form-group">
                  <label htmlFor="permanent_municipality">Municipality</label>
                  <select
                    className="form-control"
                    {...register("permanent_municipality", {
                      required: "This field is required.",
                    })}
                    // name="permanent_municipality"
                    id="permanent_municipality"
                    required="required"
                  >
                    <option value="">Select municipality</option>
                  </select>
                  <span className="text-danger">
                    {errors.permanent_municipality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="permanent_ward">Ward Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Ward No"
                    id="permanent_ward"
                    required="required"
                    {...register("permanent_ward", {
                      required: "This field is required",

                      minLength: {
                        value: 2,
                        message: "Ward number min 2 digit",
                      },
                      maxLength: {
                        value: 3,
                        message: "Ward number max 3 digit",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.permanent_ward?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="form-group">
                  <label htmlFor="permanent_address">Street/ Tole</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street/ Tole"
                    id="permanent_address"
                    required="required"
                    {...register("permanent_address", {
                      required: "This field is required",
                    })}
                  />
                  <span className="text-danger">
                    {errors.permanent_address?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a7">Current Address</h4>
            <div className="row">
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="temporary_provience">Province</label>
                  <select
                    className="form-control"
                    id="temporary_provience"
                    {...register("temporary_provience", {
                      required: "This field is required.",
                    })}
                    name="temporary_provience"
                    value={TProvience}
                    onChange={ChangeProvienceT}
                  >
                    <option value="">Select Province</option>
                    {Provience?.map((p, key) => {
                      return (
                        <option key={key} value={p.id}>
                          {p.title}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.temporary_provience?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="temporary_district"> District</label>
                  <select
                    className="form-control"
                    {...register("temporary_district", {
                      required: "This field is required.",
                    })}
                    // name="temporary_district"
                    id="temporary_district"
                    value={STDistict}
                    onChange={ChangeDistictST}
                  >
                    <option value="">Select District</option>
                    <SelectDistrict />
                  </select>
                  <span className="text-danger">
                    {errors.temporary_district?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="form-group">
                  <label htmlFor="temporary_municipality">Municipality</label>
                  <select
                    className="form-control"
                    {...register("temporary_municipality", {
                      required: "This field is required.",
                    })}
                    name="temporary_municipality"
                    id="temporary_municipality"
                  >
                    <option value="">Select municipality</option>
                  </select>
                  <span className="text-danger">
                    {errors.temporary_municipality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="form-group">
                  <label htmlFor="temporary_ward"> Ward Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="temporary_ward"
                    placeholder="Ward No"
                    {...register("temporary_ward", {
                      required: "This field is required.",
                      minLength: {
                        value: 2,
                        message: "Ward number min 3 digit",
                      },
                      maxLength: {
                        value: 3,
                        message: "Ward number max 3 digit",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.temporary_ward?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3">
                <div className="form-group">
                  <label htmlFor="temporary_address">Street/ Tole</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street/ Tole"
                    id="temporary_address"
                    {...register("temporary_address", {
                      required: "This field is required",
                    })}
                  />
                  <span className="text-danger">
                    {errors.temporary_address?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a9"> Educational Qualification</h4>
            {educationaddmore.map((singleEducationBox, index) => (
              <>
                <div className="EDUCATION_FLEX">
                  <h5 className="EDUCATIONTITLE">
                    {index == "0"
                      ? "SEE/SLC"
                      : index == "1"
                      ? "+2/HSEB/NEB"
                      : index == "2"
                      ? "Bachelor"
                      : index == "3"
                      ? "Masters"
                      : "Others"}
                  </h5>
                  {educationaddmore.length > 1 && index != 0 && index != 1 && (
                    <div
                      className="RemoveBTN"
                      onClick={() => educationRemove(index)}
                    >
                      Remove
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="organization">Institutions name</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        placeholder="Institutions name"
                        name="organization"
                        required="required"
                        value={singleEducationBox.organization}
                        onChange={(e) => handleEducationChange(e, index)}
                        onKeyPress={(event) => {
                          if (/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="board">University</label>
                      <input
                        type="text"
                        id="board"
                        className="form-control"
                        placeholder="University"
                        name="board"
                        required="required"
                        value={singleEducationBox.board}
                        onChange={(e) => handleEducationChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.board?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="passed_year">Passed Year</label>
                      <select
                        id="passed_year"
                        className="form-control"
                        name="passed_year"
                        required="required"
                        value={singleEducationBox.passed_year}
                        onChange={(e) => handleEducationChange(e, index)}
                      >
                        <option value="">Select a Year</option>
                        {Array.from({ length: 50 }, (_, i) => {
                          const year = new Date().getFullYear() - i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">
                        {errors.passed_year?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="faculty">Specialization</label>
                      <input
                        type="text"
                        id="faculty"
                        className="form-control"
                        placeholder="Specialization"
                        name="faculty"
                        value={singleEducationBox.faculty}
                        onChange={(e) => handleEducationChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.faculty?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="degree">Qualification</label>
                      <input
                        type="text"
                        id="degree"
                        className="form-control"
                        placeholder="Qualification"
                        name="degree"
                        value={singleEducationBox.degree}
                        onChange={(e) => handleEducationChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.degree?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {educationaddmore.length < 5 && (
              <div className="AddBTNS">
                <p onClick={educationAdd}>
                  Any other qualifications Specify Add More{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </p>
              </div>
            )}

            <h4 className="animation a11">Job Experience</h4>
            {experienceaddmore.map((singleExperienceBox, index) => (
              <>
                <div className="Exp_FLEX">
                  {experienceaddmore.length > 1 && index != 0 && (
                    <div
                      className="RemoveBTN"
                      onClick={() => experienceRemove(index)}
                    >
                      Remove
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="position">Position</label>
                      <input
                        type="text"
                        id="position"
                        className="form-control"
                        name="position"
                        placeholder="Position"
                        value={singleExperienceBox.position}
                        onChange={(e) => handleExperienceChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.position?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="date_from">From</label>
                      <input
                        type="date"
                        id="date_from"
                        className="form-control"
                        name="date_from"
                        value={singleExperienceBox.date_from}
                        onChange={(e) => handleExperienceChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.date_from?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="date_to">To</label>
                      <input
                        type="date"
                        id="date_to"
                        className="form-control"
                        name="date_to"
                        value={singleExperienceBox.date_to}
                        onChange={(e) => handleExperienceChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.date_to?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="total_month">Total months</label>
                      <input
                        type="text"
                        id="total_month"
                        className="form-control"
                        placeholder="Total months"
                        name="total_month"
                        value={singleExperienceBox.total_month || ""} // Ensuring value is string
                        readOnly // Make it read-only since it's calculated
                      />
                      <span className="text-danger">
                        {errors.total_month?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="organization">Company's name</label>
                      <input
                        type="text"
                        id="organization"
                        placeholder="Company's name"
                        className="form-control"
                        name="organization"
                        value={singleExperienceBox.organization}
                        onChange={(e) => handleExperienceChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {experienceaddmore.length < 5 && (
              <div className="AddBTNS">
                <p onClick={experienceAdd}>
                  Add More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </p>
              </div>
            )}

            <h4>
              Training <span>(If any)</span>
            </h4>
            {trainingaddmore.map((singleTrainingBox, index) => (
              <>
                <div className="Exp_FLEX">
                  {trainingaddmore.length > 1 && index != 0 && (
                    <div
                      className="RemoveBTN"
                      onClick={() => trainingRemove(index)}
                    >
                      Remove
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="title">Name</label>
                      <input
                        type="text"
                        id="title"
                        className="form-control"
                        placeholder="Name"
                        name="title"
                        value={singleTrainingBox.title}
                        onChange={(e) => handleTrainingChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.title?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="organization">Institute name</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        placeholder="Institute name"
                        name="organization"
                        value={singleTrainingBox.organization}
                        onChange={(e) => handleTrainingChange(e, index)}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="traning_date">Start Date</label>
                      <input
                        type="date"
                        id="training_date"
                        className="form-control"
                        name="training_date"
                        value={singleTrainingBox.training_date}
                        onChange={(e) => handleTrainingChange(e, index)}
                      />
                      <span className="text-danger">
                        {errors.training_date?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="traning_date_to">End Date</label>
                      <input
                        type="date"
                        id="training_date_to"
                        className="form-control"
                        name="training_date_to"
                        value={singleTrainingBox.training_date_to}
                        onChange={(e) => handleTrainingChange(e, index)}
                      />
                      <span className="text-danger">
                        {errors.training_date_to?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {trainingaddmore.length < 5 && (
              <div className="AddBTNS">
                <p onClick={trainingAdd}>
                  Add More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </p>
              </div>
            )}

            <h4>
              References <span>(Atleast two Reference is required)</span>
            </h4>
            {referencesaddmore.map((singleRefreBox, index) => (
              <>
                <div className="Exp_FLEX">
                  {referencesaddmore.length > 1 && index != 0 && (
                    <div
                      className="RemoveBTN"
                      onClick={() => referencesRemove(index)}
                    >
                      Remove
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="title">Full Name</label>
                      <input
                        type="text"
                        id="title"
                        className="form-control"
                        placeholder="Full Name"
                        name="name"
                        value={singleRefreBox.name}
                        onChange={(e) => handleReferencesChange(e, index)}
                        onKeyPress={(event) => {
                          if (/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={30}
                      />
                      <span className="text-danger">
                        {errors.title?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="organization">Organization</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        name="organization"
                        placeholder="Organization"
                        value={singleRefreBox.organization}
                        onChange={(e) => handleReferencesChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="organization">Position</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        name="position"
                        placeholder="Position"
                        value={singleRefreBox.position}
                        onChange={(e) => handleReferencesChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-group">
                      <label htmlFor="organization">Address</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        name="address"
                        placeholder="Address"
                        value={singleRefreBox.address}
                        onChange={(e) => handleReferencesChange(e, index)}
                        maxLength={80}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="organization">Mobile Number</label>
                      <input
                        type="text"
                        id="organization"
                        className="form-control"
                        name="contact"
                        placeholder="Mobile Number"
                        value={singleRefreBox.contact}
                        onChange={(e) => handleReferencesChange(e, index)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={10}
                      />
                      <span className="text-danger">
                        {errors.organization?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {referencesaddmore.length < 3 && (
              <div className="AddBTNS">
                <p onClick={referencesAdd}>
                  Add More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </p>
              </div>
            )}
            <h4 className="animation a17">Upload Documents</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group disflexx">
                  <div className="LabelTitle">
                    <p>education qualification cerficates</p>
                    <span>Must be less than 5MB</span>
                  </div>
                  <div className="FIleUploadss">
                    <input
                      type="file"
                      {...register("application", {
                        required: "This field is required.",
                        validate: {
                          maxSize: (files) => {
                            const file = files[0];
                            return (
                              (file && file.size <= 5 * 1024 * 1024) ||
                              "File size must be less than 5MB."
                            );
                          },
                        },
                      })}
                      name="application"
                      id="application"
                      required="required"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      className="form-field form-controls animation a18"
                      onChange={(e) => handleAppChange(e)}
                    />
                    <label htmlFor="application">
                      <span>Upload</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                    </label>
                    {fileName && <p className="FIleName">{fileName}</p>}
                    <span className="text-danger">
                      {errors.application?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group disflexx">
                  <div className="LabelTitle">
                    <p>Curriculum Vitae (CV) </p>
                    <span>Must be less than 500KB</span>
                  </div>
                  <div className="FIleUploadss">
                    <input
                      type="file"
                      {...register("cv", {
                        required: "This field is required.",
                        validate: {
                          maxSize: (files) => {
                            const file = files[0];
                            return (
                              (file && file.size <= 500 * 1024) ||
                              "File size must be less than 500 KB."
                            );
                          },
                        },
                      })}
                      name="cv"
                      id="cv"
                      required="required"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      className="form-field form-controls animation a18"
                      onChange={(e) => handleCVChange(e)}
                    />
                    <label htmlFor="cv">
                      <span>Upload</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                    </label>
                    {fileNameCV && <p className="FIleName">{fileNameCV}</p>}
                    <span className="text-danger">{errors.cv?.message}</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="form-group disflexx">
                  <div className="LabelTitle">
                    <p>Passport size Photo </p>
                    <span>Must be less than 500KB</span>
                  </div>
                  <div className="FIleUploadss">
                    <input
                      type="file"
                      {...register("DocPath", {
                        required: "This field is required.",
                        validate: {
                          maxSize: (files) => {
                            const file = files[0];
                            return (
                              (file && file.size <= 500 * 1024) ||
                              "File size must be less than 500 KB."
                            );
                          },
                        },
                      })}
                      name="DocPath"
                      id="DocPath"
                      required="required"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      className="form-field form-controls animation a18"
                      placeholder="DocPath"
                      onChange={(e) => handleDocPathChange(e)}
                    />
                    <label htmlFor="DocPath">
                      <span>Upload</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                    </label>
                    {fileNamepic && <p className="FIleName">{fileNamepic}</p>}
                    <span className="text-danger">
                      {errors.DocPath?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="form-group disflexx">
                  <div className="LabelTitle">
                    <p>Citizenship(Front & Back)</p>
                    <span>Must be less than 3MB</span>
                  </div>
                  <div className="FIleUploadss">
                    <input
                      type="file"
                      {...register("citizenship", {
                        required: "This field is required.",
                        validate: {
                          maxSize: (files) => {
                            const file = files[0];
                            return (
                              (file && file.size <= 3 * 1024 * 1024) ||
                              "File size must be less than 3MB."
                            );
                          },
                        },
                      })}
                      name="citizenship"
                      id="citizenship"
                      required="required"
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      className="form-field form-controls animation a18"
                      placeholder="citizenship"
                      onChange={(e) => handlecitizenshipChange(e)}
                    />
                    <label htmlFor="citizenship">
                      <span>Upload</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                    </label>
                    {fileNamecitizen && (
                      <p className="FIleName">{fileNamecitizen}</p>
                    )}
                    <span className="text-danger">
                      {errors.citizenship?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              {loading && <ClipLoader size={20} color="#fff"></ClipLoader>}
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default CareerForm;

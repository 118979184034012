import React from "react";
import SelectBox from "./SelectBox";
import axios from "axios";

function ProvienceSelect() {
  const [provience, setProvience] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}province`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setProvience(response.data.provience.en);
        //console.log("response.data", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return "";
  if (error) return "";
  return <SelectBox items={provience} />;
}

export default ProvienceSelect;
